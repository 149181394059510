<script>
    import moment from "moment"

    /**
     * App-contacts component
     */
    export default {
        page: {title: 'Στατιστικά διατροφικών πλάνων'},
        data() {
            return {
                pieOptions:{
                    chart: {width: 250, type: 'pie',},
                    labels: ['Συντήρηση Βάρους', 'Μείωση Βάρους', 'Αύξηση Βάρους'],
                    colors:['#3155A6','#f5b94b', '#92a9a4'],
                    tooltip:{y: {formatter: (value) => { return value + ' πλάνα' }}},
                    legend:{position: 'bottom'},
                    stroke:{width:0},
                    responsive: [{breakpoint: 480, options: {chart: {width: 220, height:300}, legend: {position: 'bottom'}}}]
                },
                goalSeries: [0,0,0],
                customerSeries:{
                    labels: null,
                    series: [{
                        name: 'Αυτός ο μήνας',
                        data: []
                    },
                        {name: 'Προηγούμενος μήνας',
                            data: []
                        },],
                    dietPlanSeries: [{
                        name: 'Αυτός ο μήνας',
                        data: []
                    },
                        {
                            name: 'Προηγούμενος μήνας',
                            data: []
                        }]
                },

                seriesLoaded: false,
                statistics: {},
                lastUpdated: null

            }
        },

        created() {
            this.fetchStatistics().then(res => {
                this.getStatistics();
            });


        },
        methods: {

            getStatistics(){
                this.statistics = this.$store.state.dietPlan._dietPlanStatistics;
                this.lastUpdated = this.$store.state.dietPlan.lastUpdated;

                this.customerSeries.dietPlanSeries[0].data = this.statistics.dietPlans.newCount;
                this.customerSeries.dietPlanSeries[1].data = this.statistics.dietPlans.lastMonthCount;
                this.dpCount = this.statistics.dietPlans.totalCount;
                this.customerSeries.labels = this.statistics.labels;
                this.goalSeries[0] = this.statistics.dietPlans.maintenanceCount;
                this.goalSeries[1] = this.statistics.dietPlans.decreaseCount;
                this.goalSeries[2] = this.statistics.dietPlans.increaseCount;
                this.seriesLoaded = true;
            },
            async fetchStatistics(){
                if(!this.$store.state.dietPlan._dietPlanStatistics || !localStorage.getItem('dietPlans.statistics')) {
                    await this.$store.dispatch('dietPlan/cacheDpStatistics')
                }else{
                    await this.$store.dispatch('dietPlan/refreshCacheStatistics');
                }
            },
            async reFetchStatistics(){
                this.seriesLoaded = false;
                this.$store.dispatch('dietPlan/cacheDpStatistics').then(res => {
                    this.getStatistics();
                    this.seriesLoaded=true;
                });

            },
            getOptions(type){
                let diagramOpt = {
                    chart: {
                        toolbar: {
                            show: false,
                            tools: {
                                download: false,
                                selection: false,
                                zoom: false,
                                zoomin: false,
                                zoomout: false,
                                pan: false,
                                reset:false,
                                customIcons: []
                            },
                        },

                    },
                    tooltip:{
                        y: {
                        },
                    },
                    stroke:{
                        width: 2,
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: this.dayNames
                    },
                    yaxis: {
                        forceNiceScale: true
                    },
                    animations: {
                        enabled: false
                    }
                };

                diagramOpt.chart.id = 'new customers';
                diagramOpt.tooltip.y = type==='customer' ? {formatter: (value) => { return value + ' πελάτες' }} : {formatter: (value) => { return value + ' διατροφικά πλάνα' }};
                diagramOpt.colors = type==='customer'? ['#3155A6', 'rgba(42, 158, 251, .2)'] : ['#1abc9c', 'rgba(10, 74, 61, .2)'];
                diagramOpt.xaxis.categories = this.customerSeries.labels;
                return diagramOpt;
            },

            getUpdatedDate(){
                return moment(this.lastUpdated).format('DD-MM-YYYY HH:mm');
            }
        },
    }
</script>

<template>
  <b-card class="mt-4">
            <b-card-title>Στατιστικά διατροφικών πλάνων</b-card-title>
            <b-card-sub-title>Δείτε χρήσιμα στατιστικά για τα διατροφικά πλάνα σας</b-card-sub-title>
            <div class="text-right">
                Τελευταία ενημέρωση: {{getUpdatedDate()}} <b-button variant="primary" @click="reFetchStatistics"><i class="icon-refresh"></i></b-button>
            </div>

            <b-card-body>
                <b-overlay :show="!seriesLoaded" rounded="sm">
                    <div v-if="seriesLoaded">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="widget-rounded-circle card-box shadow-sm">
                                    <div class="row">
                                        <div class="col-6">
                                            <div :class="`avatar-lg rounded-circle bg-soft-warning border-warning border`">
                                                <i class="icon-notebook font-22 avatar-title text-warning"></i>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="text-right">
                                                <h3 class="text-dark mt-1"><span> {{dpCount}}</span></h3>
                                                <p class="text-muted mb-1 text-truncate">Διατροφικά πλάνα</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end row-->
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="widget-rounded-circle card-box shadow-sm">
                                    <div class="row">
                                        <div class="col-6">
                                            <img src="@assets/images/icons/icon-vegan.png" width="75px" alt="icon-vegan"/>
                                        </div>
                                        <div class="col-6">
                                            <div class="text-right">
                                                <h3 class="text-dark mt-1"><span> {{statistics.dietPlans.veganCount}} </span></h3>
                                                <p class="text-muted mb-1 text-truncate">Vegan</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end row-->
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="widget-rounded-circle card-box shadow-sm">
                                    <div class="row">
                                        <div class="col-6">
                                            <img src="@assets/images/icons/icon-fasting.png" width="75px" alt="icon-vegan"/>
                                        </div>
                                        <div class="col-6">
                                            <div class="text-right">
                                                <h3 class="text-dark mt-1"><span> {{statistics.dietPlans.fastingCount}} </span></h3>
                                                <p class="text-muted mb-1 text-truncate">Νηστίσιμα</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end row-->
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="widget-rounded-circle card-box shadow-sm">
                                    <div class="row">
                                        <div class="col-6">
                                            <img src="@assets/images/icons/icon-carbohydration.png" width="75px" alt="icon-vegan"/>
                                        </div>
                                        <div class="col-6">
                                            <div class="text-right">
                                                <h3 class="text-dark mt-1"><span> {{statistics.dietPlans.carbohydrationCount}} </span></h3>
                                                <p class="text-muted mb-1 text-truncate">Υδατανθρακώσεις</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end row-->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <h5>Διατροφικά πλάνα</h5>
                                <apexchart height="300px" type="line" :options="getOptions()" :series="customerSeries.dietPlanSeries" ></apexchart>


                            </div>
                            <div class="col-md-6">
                                <h5>Στόχοι ανά διατροφικό πλάνο</h5>
                                <apexchart height="300px" type="pie" :options="pieOptions" :series="goalSeries" class="mt-3"></apexchart>

                            </div>
                        </div>


                    </div>

                </b-overlay>
            </b-card-body>
        </b-card>
</template>
